import type { NextPage } from "next";
import Layout from "./layout";
import {
  Achievement,
  NewsHome,
  MainContent,
  VideoHome,
} from "../components/home";
import { useEffect, useMemo } from "react";
import { get_banners, get_news_articles, get_products } from "../service/api";
import { IProduct } from "../types/product";
import { IBanner } from "../types/home";
import { INewsData } from "../types/news";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

interface IHome {
  products: IProduct[];
  banners: IBanner[];
}

const Homepage: NextPage<IHome> = ({ products, banners }) => {
  const { t } = useTranslation('common');
  const memoizedBanners = useMemo(() => banners, [banners]);
  const memoizedProducts = useMemo(() => products, [products]);

  return (
    <Layout linkText={t('nav.home')} keyRender={"Homepage"}>
      <MainContent banners={memoizedBanners} />
      <VideoHome products={memoizedProducts} />
      <Achievement />
      {/* <NewsHome newsArticles={newsArticles} /> */}
    </Layout>
  );
};

export async function getServerSideProps({ locale }: { locale: string }) {
  try {
    const [productsRes, bannersRes] = await Promise.all([
      get_products({}),
      get_banners(),
    ]);

    return {
      props: {
        ...(await serverSideTranslations(locale, ['common', 'translation'])),
        products: productsRes.data?.products || [],
        banners: bannersRes.data || [],
      },
    };
  } catch (error) {
    console.error("Error fetching data:", error);

    return {
      props: {
        ...(await serverSideTranslations(locale, ['common', 'translation'])),
        products: [],
        banners: [],
        newsArticles: [],
      },
    };
  }
}

export default Homepage;
